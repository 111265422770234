<template>
  <header class="fixed w-full bg-white">
    <nav class="flex justify-between bg-white-900 text-black">
      <div class="px-5 xl:px-12 py-3 flex w-full items-center">
        <router-link class="text-3xl font-bold font-heading" :to="{ name: 'home_page' }">
          <img class="h-9" src="/Full-12-12.png" alt="logo" />
        </router-link>
        <!-- Nav Links -->
        <ul class="hidden md:flex px-4 ml-auto font-semibold font-heading space-x-12 main-menu">
          <li>
            <router-link class="hover:text-gray-200" :to="{ name: 'home_page' }">Home</router-link>
          </li>
          <li>
            <router-link class="hover:text-gray-200" :to="{ name: 'search_page' }">Advanced Search</router-link>
          </li>
          <li>
            <router-link class="hover:text-gray-200" :to="{ name: 'support_page' }">HOW IT WORKS?</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link class="hover:text-gray-200" :to="{ name: 'login_page' }">Log In</router-link>
          </li>
          <li>
            <router-link class="hover:text-gray-200" :to="{ name: 'sign_up_page' }">Sign Up</router-link>
          </li>
        </ul>

        <div class="w-12 h-12 relative dropdown" v-if="user != null">
          <div class="group w-full h-full rounded-full overflow-hidden shadow-inner text-center bg-purple table cursor-pointer">
            <span class="hidden group-hover:table-cell text-white font-bold align-middle">KR</span>
            <img :src="imageUrl" alt="lovely avatar" class="object-cover object-center w-full h-full visible group-hover:hidden" />
          </div>
          <div class="dropdown-content">
            <div>
              <ul>
                <li>
                  <router-link :to="{ name: 'consultant_private_profile', params: { id: user.id, consultant: user } }"> Profile </router-link>
                </li>
                <li><a @click="onSignOutClicked">Sign Out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import auth_service from '@/services/auth_service';
import consultant_service from '@/services/consultant_service';
import client_service from '@/services/client_service';

export default {
  name: 'Header',
  props: {
    imageUrl: {
      type: String,
    },
  },
  data() {
    return {
      is_active: false,
      active_modal: null,
      name: null,
      email: null,
      password: null,
      country: null,
      contactNo: null,
      serviceName: null,
      category: null,
      services: [],
      consent: false,
      emailSent: false,
    };
  },
  computed: {
    dynamicData() {
      let query = this.$route.query;
      if (query.data) {
        return query.data;
      } else {
        return '';
      }
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    dynamicData(encodedData) {
      if (encodedData.length > 0) {
        let data = JSON.parse(atob(encodedData));
        this.active_modal = data.modal;
      }
    },
  },
  mounted() {
    if (this.user != null) {
      this.getDP(this.user.id);
    }
  },
  methods: {
    toggleMenu() {
      this.is_active = !this.is_active;
    },
    onSignInClicked({ email, password }) {
      auth_service
        .signIn({ email, password })
        .then(() => auth_service.getUserInfo())
        .then(({ data }) => {
          this.$store.dispatch('setUser', data.user);
          this.getDP(this.user.id);
          this.$msg.info({ title: 'Welcome', message: `Hey ${this.user.name} 👋` });
          this.close();
        })
        .catch(({ error }) => this.$msg.error({ message: error, title: 'Unable to sign in' }));
    },

    getDP(id) {
      this.$s3.getDpUrl(id).then((url) => this.$emit('update:dpUrl', url));
    },

    onGoogleSignInClicked() {
      console.log('google-sign-in');
    },

    onFacebookSignInClicked() {
      console.log('facebook-sign-in');
    },

    onSignUpClicked({ email, password }) {
      this.email = email;
      this.password = password;
      this.active_modal = 'common';
    },

    createAccount({ consult, name, country, consent }) {
      this.name = name;
      this.country = country;
      this.consent = consent;
      if (consult) {
        consultant_service
          .signupConsultant({
            name: this.name,
            email: this.email,
            password: this.password,
            country: this.country,
          })
          .then(() => this.$msg.success({ title: 'Welcome Onboard 👋', message: 'consultant signed up successfully' }))
          .catch(({ error }) => this.$msg.error({ message: error, title: 'Unable to sign up' }))
          .finally(() => this.close());
      } else {
        client_service
          .signupClient({
            name: this.name,
            email: this.email,
            password: this.password,
            country: this.country,
          })
          .then(() => {
            this.active_modal = null;
            this.$msg.success({ title: 'Welcome Onboard 👋', message: 'user signed up successfully' });
          })
          .catch(({ error }) => this.$msg.error({ message: error, title: 'Unable to sign up' }))
          .finally(() => this.close());
      }
    },

    onSignOutClicked() {
      auth_service.signOut().then(() => {
        this.$router.replace({ name: 'home_page' });
        this.$store.dispatch('setUser', null);
      });
    },

    proceedConsultant({ contactNo, serviceName, category, services }) {
      this.contactNo = contactNo;
      this.serviceName = serviceName;
      this.category = category;
      this.services = services;
      this.active_modal = 'privacy-policy';
    },
    close() {
      this.active_modal = null;
    },
    requestAccountRecovery(email) {
      auth_service
        .requestAccountRecovery(email)
        .then(() => {
          this.$msg.success({
            title: 'Account Recovery',
            message: 'We have sent you a link to reset your password<br/> please check your email',
            supportHTML: true,
          });
          this.emailSent = true;
        })
        .catch(({ response }) => this.$msg.error({ title: 'Account Recovery', message: response.data.error }));
    },
  },
};
</script>
